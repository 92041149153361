var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "topnav row" }, [
    _c("div", { staticClass: "col-xl-10" }, [
      _c(
        "nav",
        {
          staticClass: "navbar navbar-light navbar-expand-lg topnav-menu active"
        },
        [
          _c(
            "div",
            {
              staticClass: "collapse navbar-collapse active",
              attrs: { id: "topnav-menu-content" }
            },
            [
              _c(
                "ul",
                { staticClass: "navbar-nav", staticStyle: { width: "100%" } },
                [
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: { to: { name: "dashboard" } }
                        },
                        [
                          _c("i", {
                            staticClass: "fas fa-chart-area",
                            attrs: { "aria-hidden": "true" }
                          }),
                          _vm._v("Vue 360°")
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "nav-item dropdown" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link dropdown-toggle arrow-none",
                          class: {
                            "disabled-link": !_vm.canAny([
                              "create_mr",
                              "edit_mr",
                              "delete_mr",
                              "view_mr",
                              "list_mr",
                              "create_vsr",
                              "edit_vsr",
                              "delete_vsr",
                              "view_vsr",
                              "list_vsr",
                              "create_tamr",
                              "edit_tamr",
                              "delete_tamr",
                              "view_tamr",
                              "list_tamr",
                              "create_dr",
                              "edit_dr",
                              "delete_dr",
                              "view_dr",
                              "list_dr",
                              "create_maintenance_order",
                              "edit_maintenance_order",
                              "delete_maintenance_order",
                              "view_maintenance_order",
                              "list_maintenance_order",
                              "create_hosting_booking",
                              "update_hosting_booking",
                              "read_hosting_booking",
                              "delete_hosting_booking",
                              "list_hosting_booking",
                              "isp_acces_log",
                              "isp_acces_log_approve_entry",
                              "isp_acces_log_approve_exit"
                            ])
                          },
                          attrs: {
                            to: _vm.canAny([
                              "create_mr",
                              "edit_mr",
                              "delete_mr",
                              "view_mr",
                              "list_mr",
                              "create_vsr",
                              "edit_vsr",
                              "delete_vsr",
                              "view_vsr",
                              "list_vsr",
                              "create_tamr",
                              "edit_tamr",
                              "delete_tamr",
                              "view_tamr",
                              "list_tamr",
                              "create_dr",
                              "edit_dr",
                              "delete_dr",
                              "view_dr",
                              "list_dr",
                              "create_maintenance_order",
                              "edit_maintenance_order",
                              "delete_maintenance_order",
                              "view_maintenance_order",
                              "list_maintenance_order",
                              "create_hosting_booking",
                              "update_hosting_booking",
                              "read_hosting_booking",
                              "delete_hosting_booking",
                              "list_hosting_booking",
                              "isp_acces_log",
                              "isp_acces_log_approve_entry",
                              "isp_acces_log_approve_exit"
                            ])
                              ? { name: "service_catalog" }
                              : "",
                            id: "topnav-pages",
                            href: "javascript:void(0)",
                            role: "button",
                            "data-toggle": "dropdown",
                            "aria-haspopup": "true",
                            "aria-expanded": "false"
                          },
                          on: { click: _vm.onMenuClick }
                        },
                        [
                          _c("i", { staticClass: "fas fa-chalkboard mr-2" }),
                          _vm._v("Catalogue des services "),
                          _c("div", { staticClass: "arrow-down" })
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "dropdown-menu",
                          attrs: { "aria-labelledby": "topnav-pages" }
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "dropdown-item dropdown-toggle arrow-none",
                              class: {
                                "disabled-link": !_vm.canAny([
                                  "isp_acces_log",
                                  "isp_acces_log_approve_entry",
                                  "isp_acces_log_approve_exit"
                                ])
                              },
                              attrs: {
                                to: _vm.canAny([
                                  "isp_acces_log",
                                  "isp_acces_log_approve_entry",
                                  "isp_acces_log_approve_exit"
                                ])
                                  ? { name: "service_catalog.access" }
                                  : ""
                              }
                            },
                            [_vm._v("Gestion d'accès")]
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "dropdown-item dropdown-toggle arrow-none",
                              class: {
                                "disabled-link": !_vm.canAny([
                                  "create_mr",
                                  "edit_mr",
                                  "delete_mr",
                                  "view_mr",
                                  "list_mr",
                                  "create_vsr",
                                  "edit_vsr",
                                  "delete_vsr",
                                  "view_vsr",
                                  "list_vsr",
                                  "create_tamr",
                                  "edit_tamr",
                                  "delete_tamr",
                                  "view_tamr",
                                  "list_tamr",
                                  "create_dr",
                                  "edit_dr",
                                  "delete_dr",
                                  "view_dr",
                                  "list_dr"
                                ])
                              },
                              attrs: {
                                to: _vm.canAny([
                                  "create_mr",
                                  "edit_mr",
                                  "delete_mr",
                                  "view_mr",
                                  "list_mr",
                                  "create_vsr",
                                  "edit_vsr",
                                  "delete_vsr",
                                  "view_vsr",
                                  "list_vsr",
                                  "create_tamr",
                                  "edit_tamr",
                                  "delete_tamr",
                                  "view_tamr",
                                  "list_tamr",
                                  "create_dr",
                                  "edit_dr",
                                  "delete_dr",
                                  "view_dr",
                                  "list_dr"
                                ])
                                  ? { name: "requests" }
                                  : ""
                              }
                            },
                            [_vm._v("Requêtes")]
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "dropdown-item dropdown-toggle arrow-none",
                              class: {
                                "disabled-link": !_vm.canAny([
                                  "create_rest_ods",
                                  "edit_rest_ods",
                                  "delete_rest_ods",
                                  "view_rest_ods",
                                  "list_rest_ods"
                                ])
                              },
                              attrs: {
                                to: _vm.canAny([
                                  "create_rest_ods",
                                  "edit_rest_ods",
                                  "delete_rest_ods",
                                  "view_rest_ods",
                                  "list_rest_ods"
                                ])
                                  ? { name: "service_catalog.restauration" }
                                  : ""
                              }
                            },
                            [_vm._v("Restauration")]
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "dropdown-item dropdown-toggle arrow-none",
                              class: {
                                "disabled-link": !_vm.canAny([
                                  "create_host_ods",
                                  "edit_host_ods",
                                  "delete_host_ods",
                                  "view_host_ods",
                                  "list_host_ods",
                                  "create_hosting_booking",
                                  "update_hosting_booking",
                                  "read_hosting_booking",
                                  "delete_hosting_booking",
                                  "list_hosting_booking",
                                  "approve_booking",
                                  "reject_booking",
                                  "checkin_booking",
                                  "checkout_booking"
                                ])
                              },
                              attrs: {
                                to: _vm.canAny([
                                  "create_host_ods",
                                  "edit_host_ods",
                                  "delete_host_ods",
                                  "view_host_ods",
                                  "list_host_ods",
                                  "create_hosting_booking",
                                  "update_hosting_booking",
                                  "read_hosting_booking",
                                  "delete_hosting_booking",
                                  "list_hosting_booking",
                                  "approve_booking",
                                  "reject_booking",
                                  "checkin_booking",
                                  "checkout_booking"
                                ])
                                  ? { name: "ops.hosting.index" }
                                  : ""
                              }
                            },
                            [_vm._v("Hébergement")]
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "dropdown-item dropdown-toggle arrow-none",
                              class: {
                                "disabled-link": !_vm.canAny([
                                  "create_threedops_ods",
                                  "edit_threedops_ods",
                                  "delete_threedops_ods",
                                  "view_threedops_ods",
                                  "list_threedops_ods"
                                ])
                              },
                              attrs: {
                                to: _vm.canAny([
                                  "create_threedops_ods",
                                  "edit_threedops_ods",
                                  "delete_threedops_ods",
                                  "view_threedops_ods",
                                  "list_threedops_ods"
                                ])
                                  ? { name: "service_catalog.operations" }
                                  : ""
                              }
                            },
                            [_vm._v("Opérations 3D")]
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "dropdown-item dropdown-toggle arrow-none",
                              class: {
                                "disabled-link": !_vm.canAny([
                                  "create_gs_ods",
                                  "edit_gs_ods",
                                  "delete_gs_ods",
                                  "view_gs_ods",
                                  "list_gs_ods"
                                ])
                              },
                              attrs: {
                                to: _vm.canAny([
                                  "create_gs_ods",
                                  "edit_gs_ods",
                                  "delete_gs_ods",
                                  "view_gs_ods",
                                  "list_gs_ods"
                                ])
                                  ? { name: "service_catalog.green_areas" }
                                  : ""
                              }
                            },
                            [_vm._v("Espaces Verts")]
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "dropdown-item dropdown-toggle arrow-none",
                              class: {
                                "disabled-link": !_vm.canAny([
                                  "create_mr",
                                  "edit_mr",
                                  "delete_mr",
                                  "view_mr",
                                  "list_mr"
                                ])
                              },
                              attrs: {
                                to: _vm.canAny([
                                  "create_mr",
                                  "edit_mr",
                                  "delete_mr",
                                  "view_mr",
                                  "list_mr"
                                ])
                                  ? { name: "requests.maintenance.index" }
                                  : ""
                              }
                            },
                            [_vm._v("Maintenance des Installations")]
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "dropdown-item dropdown-toggle arrow-none",
                              class: {
                                "disabled-link": !_vm.canAny([
                                  "create_stock_entry",
                                  "edit_stock_entry",
                                  "delete_stock_entry",
                                  "view_stock_entry",
                                  "list_stock_entry",
                                  "create_stock_exit",
                                  "edit_stock_exit",
                                  "delete_stock_exit",
                                  "view_stock_exit",
                                  "list_stock_exit"
                                ])
                              },
                              attrs: {
                                to: _vm.canAny([
                                  "create_stock_entry",
                                  "edit_stock_entry",
                                  "delete_stock_entry",
                                  "view_stock_entry",
                                  "list_stock_entry",
                                  "create_stock_exit",
                                  "edit_stock_exit",
                                  "delete_stock_exit",
                                  "view_stock_exit",
                                  "list_stock_exit"
                                ])
                                  ? { name: "service_catalog.stock" }
                                  : ""
                              }
                            },
                            [_vm._v("Opérations Stock")]
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "dropdown-item dropdown-toggle arrow-none",
                              class: {
                                "disabled-link": !_vm.canAny([
                                  "create_control",
                                  "edit_control",
                                  "delete_control",
                                  "view_control",
                                  "list_control"
                                ])
                              },
                              attrs: {
                                to: _vm.canAny([
                                  "create_control",
                                  "edit_control",
                                  "delete_control",
                                  "view_control",
                                  "list_control"
                                ])
                                  ? { name: "service_catalog.hse" }
                                  : ""
                              }
                            },
                            [_vm._v("HSE")]
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "dropdown-item dropdown-toggle arrow-none",
                              class: {
                                "disabled-link": !_vm.canAny([
                                  "create_custom_ods",
                                  "edit_custom_ods",
                                  "delete_custom_ods",
                                  "view_custom_ods",
                                  "list_custom_ods"
                                ])
                              },
                              attrs: {
                                to: _vm.canAny([
                                  "create_custom_ods",
                                  "edit_custom_ods",
                                  "delete_custom_ods",
                                  "view_custom_ods",
                                  "list_custom_ods"
                                ])
                                  ? { name: "service_catalog.custom_services" }
                                  : ""
                              }
                            },
                            [_vm._v("Autres Services")]
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "dropdown-item dropdown-toggle arrow-none",
                              class: {
                                "disabled-link": !_vm.canAny([
                                  "personal_transp_log",
                                  "personal_transp_log_approve_entry",
                                  "personal_transp_log_approve_exit"
                                ])
                              },
                              attrs: {
                                to: _vm.canAny([
                                  "personal_transp_log",
                                  "personal_transp_log_approve_entry",
                                  "personal_transp_log_approve_exit"
                                ])
                                  ? {
                                      name:
                                        "service_catalog.personels_transport"
                                    }
                                  : ""
                              }
                            },
                            [_vm._v("Transport du Personnel")]
                          ),
                          _c(
                            "a",
                            {
                              staticClass:
                                "dropdown-item dropdown-toggle arrow-none disabled-link",
                              attrs: { href: "javascript:void(0)" }
                            },
                            [_vm._v("Entretien & Nettoyage")]
                          ),
                          _c(
                            "a",
                            {
                              staticClass:
                                "dropdown-item dropdown-toggle arrow-none disabled-link",
                              attrs: { href: "javascript:void(0)" }
                            },
                            [_vm._v("Blanchisserie & Dégraissage")]
                          ),
                          _c(
                            "a",
                            {
                              staticClass:
                                "dropdown-item dropdown-toggle arrow-none disabled-link",
                              attrs: { href: "javascript:void(0)" }
                            },
                            [_vm._v("Transport de Marchandise")]
                          ),
                          _c(
                            "a",
                            {
                              staticClass:
                                "dropdown-item dropdown-toggle arrow-none disabled-link",
                              attrs: { href: "javascript:void(0)" }
                            },
                            [
                              _vm._v(
                                "Autres Prestations & Fournitures diverses"
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "nav-item dropdown" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link dropdown-toggle arrow-none",
                          class: {
                            "disabled-link": !_vm.canAny([
                              "db_view_contract_dashboard",
                              "create_inv_att",
                              "edit_inv_att",
                              "delete_inv_att",
                              "view_inv_att",
                              "list_inv_att",
                              "create_supp_inv",
                              "edit_supp_inv",
                              "delete_supp_inv",
                              "view_supp_inv",
                              "list_supp_inv",
                              "create_supp_inv_pay",
                              "edit_supp_inv_pay",
                              "delete_supp_inv_pay",
                              "view_supp_inv_pay",
                              "list_supp_inv_pay"
                            ])
                          },
                          attrs: {
                            to: _vm.canAny([
                              "db_view_contract_dashboard",
                              "create_inv_att",
                              "edit_inv_att",
                              "delete_inv_att",
                              "view_inv_att",
                              "list_inv_att",
                              "create_supp_inv",
                              "edit_supp_inv",
                              "delete_supp_inv",
                              "view_supp_inv",
                              "list_supp_inv",
                              "create_supp_inv_pay",
                              "edit_supp_inv_pay",
                              "delete_supp_inv_pay",
                              "view_supp_inv_pay",
                              "list_supp_inv_pay"
                            ])
                              ? { name: "monitoring_and_management" }
                              : "",
                            id: "topnav-pages",
                            href: "javascript:void(0)",
                            role: "button",
                            "data-toggle": "dropdown",
                            "aria-haspopup": "true",
                            "aria-expanded": "false"
                          },
                          on: { click: _vm.onMenuClick }
                        },
                        [
                          _c("i", { staticClass: "fas fa-check-double mr-2" }),
                          _vm._v("Suivi & Gestion "),
                          _c("div", { staticClass: "arrow-down" })
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "dropdown-menu",
                          attrs: { "aria-labelledby": "topnav-pages" }
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "dropdown-item dropdown-toggle arrow-none",
                              class: {
                                "disabled-link": !_vm.$can(
                                  "db_view_contract_dashboard"
                                )
                              },
                              attrs: {
                                to: _vm.$can("db_view_contract_dashboard")
                                  ? {
                                      name: "monitoring_and_management.contract"
                                    }
                                  : ""
                              }
                            },
                            [_vm._v("Suivi des contrats")]
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "dropdown-item dropdown-toggle arrow-none",
                              class: {
                                "disabled-link": !_vm.canAny([
                                  "create_inv_att",
                                  "edit_inv_att",
                                  "delete_inv_att",
                                  "view_inv_att",
                                  "list_inv_att"
                                ])
                              },
                              attrs: {
                                to: _vm.canAny([
                                  "create_inv_att",
                                  "edit_inv_att",
                                  "delete_inv_att",
                                  "view_inv_att",
                                  "list_inv_att"
                                ])
                                  ? { name: "invoicing" }
                                  : ""
                              }
                            },
                            [_vm._v("Ordonnancement")]
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "dropdown-item dropdown-toggle arrow-none",
                              class: {
                                "disabled-link": !_vm.canAny([
                                  "create_supp_inv",
                                  "edit_supp_inv",
                                  "delete_supp_inv",
                                  "view_supp_inv",
                                  "list_supp_inv",
                                  "create_supp_inv_pay",
                                  "edit_supp_inv_pay",
                                  "delete_supp_inv_pay",
                                  "view_supp_inv_pay",
                                  "list_supp_inv_pay"
                                ])
                              },
                              attrs: {
                                to: _vm.canAny([
                                  "create_supp_inv",
                                  "edit_supp_inv",
                                  "delete_supp_inv",
                                  "view_supp_inv",
                                  "list_supp_inv",
                                  "create_supp_inv_pay",
                                  "edit_supp_inv_pay",
                                  "delete_supp_inv_pay",
                                  "view_supp_inv_pay",
                                  "list_supp_inv_pay"
                                ])
                                  ? {
                                      name:
                                        "monitoring_and_management.suppliers_invoices"
                                    }
                                  : ""
                              }
                            },
                            [_vm._v("Suivi de Facturation")]
                          ),
                          _c(
                            "a",
                            {
                              staticClass:
                                "dropdown-item dropdown-toggle arrow-none disabled-link",
                              attrs: { href: "javascript:void(0)" }
                            },
                            [_vm._v("Gestion du Parc roulant")]
                          ),
                          _c(
                            "a",
                            {
                              staticClass:
                                "dropdown-item dropdown-toggle arrow-none disabled-link",
                              attrs: { href: "javascript:void(0)" }
                            },
                            [_vm._v("Service Transport Personnel")]
                          ),
                          _c(
                            "a",
                            {
                              staticClass:
                                "dropdown-item dropdown-toggle arrow-none disabled-link",
                              attrs: { href: "javascript:void(0)" }
                            },
                            [_vm._v("Service Aérien")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "nav-item dropdown" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link dropdown-toggle arrow-none",
                          class: {
                            "disabled-link": !_vm.canAny([
                              "create_hierarchie",
                              "update_hierarchie",
                              "read_hierarchie",
                              "delete_hierarchie",
                              "list_hierarchie",
                              "create_camp",
                              "update_camp",
                              "read_camp",
                              "delete_camp",
                              "list_camp",
                              "create_infra",
                              "update_infra",
                              "read_infra",
                              "delete_infra",
                              "list_infra",
                              "create_hosting_room",
                              "update_hosting_room",
                              "read_hosting_room",
                              "delete_hosting_room",
                              "list_hosting_room",
                              "create_kitchen",
                              "update_kitchen",
                              "read_kitchen",
                              "delete_kitchen",
                              "list_kitchen",
                              "create_supplier",
                              "edit_supplier",
                              "delete_supplier",
                              "view_supplier",
                              "list_supplier",
                              "create_contract",
                              "edit_contract",
                              "delete_contract",
                              "view_contract",
                              "list_contract",
                              "create_employee",
                              "edit_employee",
                              "delete_employee",
                              "view_employee",
                              "list_employee",
                              "create_animal",
                              "edit_animal",
                              "delete_animal",
                              "view_animal",
                              "list_animal",
                              "create_plant",
                              "edit_plant",
                              "delete_plant",
                              "view_plant",
                              "list_plant",
                              "create_aac",
                              "edit_aac",
                              "delete_aac",
                              "view_aac",
                              "list_aac",
                              "create_visitor",
                              "edit_visitor",
                              "delete_visitor",
                              "view_visitor",
                              "list_visitor",
                              "create_equipment",
                              "edit_equipment",
                              "delete_equipment",
                              "view_equipment",
                              "list_equipment",
                              "create_product",
                              "edit_product",
                              "delete_product",
                              "view_product",
                              "list_product",
                              "create_product_categ",
                              "edit_product_categ",
                              "delete_product_categ",
                              "view_product_categ",
                              "list_product_categ",
                              "create_warehouse",
                              "edit_warehouse",
                              "delete_warehouse",
                              "view_warehouse",
                              "list_warehouse",
                              "create_extc",
                              "edit_extc",
                              "delete_extc",
                              "view_extc",
                              "list_extc"
                            ])
                          },
                          attrs: {
                            to: _vm.canAny([
                              "create_hierarchie",
                              "update_hierarchie",
                              "read_hierarchie",
                              "delete_hierarchie",
                              "list_hierarchie",
                              "create_camp",
                              "update_camp",
                              "read_camp",
                              "delete_camp",
                              "list_camp",
                              "create_infra",
                              "update_infra",
                              "read_infra",
                              "delete_infra",
                              "list_infra",
                              "create_hosting_room",
                              "update_hosting_room",
                              "read_hosting_room",
                              "delete_hosting_room",
                              "list_hosting_room",
                              "create_kitchen",
                              "update_kitchen",
                              "read_kitchen",
                              "delete_kitchen",
                              "list_kitchen",
                              "create_supplier",
                              "edit_supplier",
                              "delete_supplier",
                              "view_supplier",
                              "list_supplier",
                              "create_contract",
                              "edit_contract",
                              "delete_contract",
                              "view_contract",
                              "list_contract",
                              "create_employee",
                              "edit_employee",
                              "delete_employee",
                              "view_employee",
                              "list_employee",
                              "create_animal",
                              "edit_animal",
                              "delete_animal",
                              "view_animal",
                              "list_animal",
                              "create_plant",
                              "edit_plant",
                              "delete_plant",
                              "view_plant",
                              "list_plant",
                              "create_aac",
                              "edit_aac",
                              "delete_aac",
                              "view_aac",
                              "list_aac",
                              "create_visitor",
                              "edit_visitor",
                              "delete_visitor",
                              "view_visitor",
                              "list_visitor",
                              "create_equipment",
                              "edit_equipment",
                              "delete_equipment",
                              "view_equipment",
                              "list_equipment",
                              "create_product",
                              "edit_product",
                              "delete_product",
                              "view_product",
                              "list_product",
                              "create_product_categ",
                              "edit_product_categ",
                              "delete_product_categ",
                              "view_product_categ",
                              "list_product_categ",
                              "create_warehouse",
                              "edit_warehouse",
                              "delete_warehouse",
                              "view_warehouse",
                              "list_warehouse",
                              "create_extc",
                              "edit_extc",
                              "delete_extc",
                              "view_extc",
                              "list_extc"
                            ])
                              ? { name: "master_data" }
                              : "",
                            id: "topnav-pages",
                            href: "javascript:void(0)",
                            role: "button",
                            "data-toggle": "dropdown",
                            "aria-haspopup": "true",
                            "aria-expanded": "false"
                          },
                          on: { click: _vm.onMenuClick }
                        },
                        [
                          _c("i", { staticClass: "fas fa-database mr-2" }),
                          _vm._v("Master Data "),
                          _c("div", { staticClass: "arrow-down" })
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "dropdown-menu",
                          attrs: { "aria-labelledby": "topnav-pages" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "dropdown" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item dropdown-toggle",
                                  attrs: {
                                    to: _vm.canAny([
                                      "create_hierarchie",
                                      "update_hierarchie",
                                      "read_hierarchie",
                                      "delete_hierarchie",
                                      "list_hierarchie",
                                      "create_infra",
                                      "update_infra",
                                      "read_infra",
                                      "delete_infra",
                                      "list_infra",
                                      "create_hosting_room",
                                      "update_hosting_room",
                                      "read_hosting_room",
                                      "delete_hosting_room",
                                      "list_hosting_room",
                                      "create_kitchen",
                                      "update_kitchen",
                                      "read_kitchen",
                                      "delete_kitchen",
                                      "list_kitchen",
                                      "create_supplier",
                                      "edit_supplier",
                                      "delete_supplier",
                                      "view_supplier",
                                      "list_supplier",
                                      "create_employee",
                                      "edit_employee",
                                      "delete_employee",
                                      "view_employee",
                                      "list_employee",
                                      "create_animal",
                                      "edit_animal",
                                      "delete_animal",
                                      "view_animal",
                                      "list_animal",
                                      "create_plant",
                                      "edit_plant",
                                      "delete_plant",
                                      "view_plant",
                                      "list_plant",
                                      "create_aac",
                                      "edit_aac",
                                      "delete_aac",
                                      "view_aac",
                                      "list_aac",
                                      "create_visitor",
                                      "edit_visitor",
                                      "delete_visitor",
                                      "view_visitor",
                                      "list_visitor",
                                      "create_equipment",
                                      "edit_equipment",
                                      "delete_equipment",
                                      "view_equipment",
                                      "list_equipment",
                                      "create_product",
                                      "edit_product",
                                      "delete_product",
                                      "view_product",
                                      "list_product",
                                      "create_product_categ",
                                      "edit_product_categ",
                                      "delete_product_categ",
                                      "view_product_categ",
                                      "list_product_categ",
                                      "create_warehouse",
                                      "edit_warehouse",
                                      "delete_warehouse",
                                      "view_warehouse",
                                      "list_warehouse",
                                      "create_extc",
                                      "edit_extc",
                                      "delete_extc",
                                      "view_extc",
                                      "list_extc"
                                    ])
                                      ? { name: "master_data.referentiels" }
                                      : ""
                                  }
                                },
                                [
                                  _vm._v("Référentiels "),
                                  _c("div", { staticClass: "arrow-down" })
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "dropdown-menu" },
                                [
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "dropdown-item side-nav-link-ref",
                                        class: {
                                          "disabled-link": !_vm.canAny([
                                            "create_hierarchie",
                                            "update_hierarchie",
                                            "read_hierarchie",
                                            "delete_hierarchie",
                                            "list_hierarchie"
                                          ])
                                        },
                                        attrs: {
                                          to: _vm.canAny([
                                            "create_hierarchie",
                                            "update_hierarchie",
                                            "read_hierarchie",
                                            "delete_hierarchie",
                                            "list_hierarchie"
                                          ])
                                            ? {
                                                name:
                                                  "master_data.referentiels.index"
                                              }
                                            : ""
                                        }
                                      },
                                      [_vm._v("Gestion Des Structures")]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "dropdown-item side-nav-link-ref",
                                        class: {
                                          "disabled-link": !_vm.canAny([
                                            "create_infra",
                                            "update_infra",
                                            "read_infra",
                                            "delete_infra",
                                            "list_infra"
                                          ])
                                        },
                                        attrs: {
                                          to: _vm.canAny([
                                            "create_infra",
                                            "update_infra",
                                            "read_infra",
                                            "delete_infra",
                                            "list_infra"
                                          ])
                                            ? {
                                                name:
                                                  "master_data.referentiels.infrastructure"
                                              }
                                            : ""
                                        }
                                      },
                                      [_vm._v("Gestion Des Infrastrutures")]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "dropdown-item side-nav-link-ref",
                                        class: {
                                          "disabled-link": !_vm.canAny([
                                            "create_extc",
                                            "edit_extc",
                                            "delete_extc",
                                            "view_extc",
                                            "list_extc",
                                            "create_ie",
                                            "update_ie",
                                            "read_ie",
                                            "delete_ie",
                                            "list_ie",
                                            "create_supplier",
                                            "edit_supplier",
                                            "delete_supplier",
                                            "view_supplier",
                                            "list_supplier"
                                          ])
                                        },
                                        attrs: {
                                          to: {
                                            name:
                                              "master_data.referentiels.extreme_business"
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "Gestion Des Entreprises Externe"
                                        )
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "dropdown-item side-nav-link-ref",
                                        class: {
                                          "disabled-link": !_vm.canAny([
                                            "create_employee",
                                            "edit_employee",
                                            "delete_employee",
                                            "view_employee",
                                            "list_employee",
                                            "create_visitor",
                                            "edit_visitor",
                                            "delete_visitor",
                                            "view_visitor",
                                            "list_visitor"
                                          ])
                                        },
                                        attrs: {
                                          to: _vm.canAny([
                                            "create_employee",
                                            "edit_employee",
                                            "delete_employee",
                                            "view_employee",
                                            "list_employee"
                                          ])
                                            ? {
                                                name:
                                                  "master_data.referentiels.users"
                                              }
                                            : ""
                                        }
                                      },
                                      [_vm._v("Gestion Du Personnel")]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "dropdown" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "dropdown-item dropdown-toggle",
                                            class: {
                                              "disabled-link": !_vm.canAny([
                                                "create_product",
                                                "edit_product",
                                                "delete_product",
                                                "view_product",
                                                "list_product",
                                                "create_product_categ",
                                                "edit_product_categ",
                                                "delete_product_categ",
                                                "view_product_categ",
                                                "list_product_categ",
                                                "create_warehouse",
                                                "edit_warehouse",
                                                "delete_warehouse",
                                                "view_warehouse",
                                                "list_warehouse"
                                              ])
                                            },
                                            attrs: {
                                              to: _vm.canAny([
                                                "create_product",
                                                "edit_product",
                                                "delete_product",
                                                "view_product",
                                                "list_product",
                                                "create_product_categ",
                                                "edit_product_categ",
                                                "delete_product_categ",
                                                "view_product_categ",
                                                "list_product_categ",
                                                "create_warehouse",
                                                "edit_warehouse",
                                                "delete_warehouse",
                                                "view_warehouse",
                                                "list_warehouse"
                                              ])
                                                ? {
                                                    name:
                                                      "master_data.referentiels.stock_management"
                                                  }
                                                : ""
                                            }
                                          },
                                          [
                                            _vm._v(" Gestion de Stock "),
                                            _c("div", {
                                              staticClass: "arrow-down"
                                            })
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "dropdown-menu" },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                staticClass:
                                                  "dropdown-item side-nav-link-ref",
                                                class: {
                                                  "disabled-link": !_vm.canAny([
                                                    "create_warehouse",
                                                    "edit_warehouse",
                                                    "delete_warehouse",
                                                    "view_warehouse",
                                                    "list_warehouse"
                                                  ])
                                                },
                                                attrs: {
                                                  to: {
                                                    name:
                                                      "settings.warehouses.index"
                                                  }
                                                }
                                              },
                                              [_vm._v("Gestion Des Magasins")]
                                            ),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass:
                                                  "dropdown-item side-nav-link-ref",
                                                class: {
                                                  "disabled-link": !_vm.canAny([
                                                    "create_product_categ",
                                                    "edit_product_categ",
                                                    "delete_product_categ",
                                                    "view_product_categ",
                                                    "list_product_categ"
                                                  ])
                                                },
                                                attrs: {
                                                  to: _vm.canAny([
                                                    "create_product_categ",
                                                    "edit_product_categ",
                                                    "delete_product_categ",
                                                    "view_product_categ",
                                                    "list_product_categ"
                                                  ])
                                                    ? {
                                                        name:
                                                          "settings.product_category.index"
                                                      }
                                                    : ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "Gestion Des catégories de produits"
                                                )
                                              ]
                                            ),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass:
                                                  "dropdown-item side-nav-link-ref",
                                                class: {
                                                  "disabled-link": !_vm.canAny([
                                                    "create_product",
                                                    "edit_product",
                                                    "delete_product",
                                                    "view_product",
                                                    "list_product"
                                                  ])
                                                },
                                                attrs: {
                                                  to: _vm.canAny([
                                                    "create_product",
                                                    "edit_product",
                                                    "delete_product",
                                                    "view_product",
                                                    "list_product"
                                                  ])
                                                    ? {
                                                        name:
                                                          "base.stock.products"
                                                      }
                                                    : ""
                                                }
                                              },
                                              [_vm._v("Gestion Des produits")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "dropdown-item side-nav-link-ref",
                                        class: {
                                          "disabled-link": !_vm.canAny([
                                            "create_animal",
                                            "edit_animal",
                                            "delete_animal",
                                            "view_animal",
                                            "list_animal"
                                          ])
                                        },
                                        attrs: {
                                          to: _vm.canAny([
                                            "create_animal",
                                            "edit_animal",
                                            "delete_animal",
                                            "view_animal",
                                            "list_animal"
                                          ])
                                            ? {
                                                name:
                                                  "master_data.referentiels.zoo.index"
                                              }
                                            : ""
                                        }
                                      },
                                      [_vm._v("Gestion Du Parc Animalier")]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "dropdown-item side-nav-link-ref",
                                        class: {
                                          "disabled-link": !_vm.canAny([
                                            "create_hosting_room",
                                            "update_hosting_room",
                                            "read_hosting_room",
                                            "delete_hosting_room",
                                            "list_hosting_room"
                                          ])
                                        },
                                        attrs: {
                                          to: _vm.canAny([
                                            "create_hosting_room",
                                            "update_hosting_room",
                                            "read_hosting_room",
                                            "delete_hosting_room",
                                            "list_hosting_room"
                                          ])
                                            ? {
                                                name:
                                                  "base.infrastructures.rooms"
                                              }
                                            : ""
                                        }
                                      },
                                      [_vm._v("Gestion Des Chambres")]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "dropdown-item side-nav-link-ref",
                                        class: {
                                          "disabled-link": !_vm.canAny([
                                            "create_kitchen",
                                            "update_kitchen",
                                            "read_kitchen",
                                            "delete_kitchen",
                                            "list_kitchen"
                                          ])
                                        },
                                        attrs: {
                                          to: _vm.canAny([
                                            "create_kitchen",
                                            "update_kitchen",
                                            "read_kitchen",
                                            "delete_kitchen",
                                            "list_kitchen"
                                          ])
                                            ? {
                                                name:
                                                  "base.infrastructures.kitchen"
                                              }
                                            : ""
                                        }
                                      },
                                      [_vm._v("Gestion Des Cuisines")]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "dropdown-item side-nav-link-ref",
                                        class: {
                                          "disabled-link": !_vm.canAny([
                                            "create_equipment",
                                            "edit_equipment",
                                            "delete_equipment",
                                            "view_equipment",
                                            "list_equipment"
                                          ])
                                        },
                                        attrs: {
                                          to: _vm.canAny([
                                            "create_equipment",
                                            "edit_equipment",
                                            "delete_equipment",
                                            "view_equipment",
                                            "list_equipment"
                                          ])
                                            ? { name: "base.misc.equipments" }
                                            : ""
                                        }
                                      },
                                      [_vm._v("Gestion Des Equipments")]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "dropdown-item side-nav-link-ref",
                                        class: {
                                          "disabled-link": !_vm.canAny([
                                            "create_aac",
                                            "edit_aac",
                                            "delete_aac",
                                            "view_aac",
                                            "list_aac"
                                          ])
                                        },
                                        attrs: {
                                          to: _vm.canAny([
                                            "create_aac",
                                            "edit_aac",
                                            "delete_aac",
                                            "view_aac",
                                            "list_aac"
                                          ])
                                            ? {
                                                name:
                                                  "master_data.referentiels.analytics"
                                              }
                                            : ""
                                        }
                                      },
                                      [_vm._v("Gestion Des compte analytics")]
                                    )
                                  ]
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "dropdown-item dropdown-toggle arrow-none",
                              class: {
                                "disabled-link": !_vm.canAny([
                                  "create_camp",
                                  "update_camp",
                                  "read_camp",
                                  "delete_camp",
                                  "list_camp"
                                ])
                              },
                              attrs: {
                                to: _vm.canAny([
                                  "create_camp",
                                  "update_camp",
                                  "read_camp",
                                  "delete_camp",
                                  "list_camp"
                                ])
                                  ? { name: "settings.lifebases" }
                                  : ""
                              }
                            },
                            [_vm._v("Fiche base de vie")]
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "dropdown-item dropdown-toggle arrow-none",
                              class: {
                                "disabled-link": !_vm.canAny([
                                  "create_contract",
                                  "edit_contract",
                                  "delete_contract",
                                  "view_contract",
                                  "list_contract"
                                ])
                              },
                              attrs: {
                                to: _vm.canAny([
                                  "create_contract",
                                  "edit_contract",
                                  "delete_contract",
                                  "view_contract",
                                  "list_contract"
                                ])
                                  ? { name: "base.contracts.contracts.index" }
                                  : ""
                              }
                            },
                            [_vm._v("Fiche contrat")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: { to: { name: "dashboard" } }
                        },
                        [
                          _c("i", { staticClass: "bx bx-home-circle mr-2" }),
                          _vm._v("Dashboard & rapports")
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          class: {
                            "disabled-link": !_vm.canAny([
                              "system_settings_crud"
                            ])
                          },
                          attrs: {
                            to: _vm.canAny(["system_settings_crud"])
                              ? { name: "base.settings.index" }
                              : ""
                          }
                        },
                        [
                          _c("i", { staticClass: "fas fa-cogs mr-2" }),
                          _vm._v("Configuration")
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ]
          )
        ]
      )
    ]),
    _c(
      "div",
      {
        staticClass:
          "col-xl-2 navbar navbar-light navbar-expand-lg topnav-menu active justify-content-center"
      },
      [
        _c(
          "nav",
          {
            staticClass: "collapse navbar-collapse active justify-content-end",
            attrs: { id: "topnav-menu-content" }
          },
          [
            _c("ul", { staticClass: "navbar-nav" }, [
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    staticStyle: { cursor: "pointer" },
                    attrs: { href: _vm.help_url, target: "_blank" }
                  },
                  [
                    _c("i", { staticClass: "fas fa-question-circle mr-2" }),
                    _vm._v("Assistance")
                  ]
                )
              ])
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }