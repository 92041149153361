var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c(
      "div",
      { attrs: { id: "layout-wrapper" } },
      [
        _c("NavBar"),
        _c("SideBar", {
          attrs: {
            "is-condensed": _vm.isMenuCondensed,
            type: _vm.leftSidebarType,
            width: _vm.layoutWidth
          }
        }),
        _c(
          "div",
          { staticClass: "main-content" },
          [
            _c("div", { staticClass: "page-content" }, [
              _c(
                "div",
                { staticClass: "container-fluid" },
                [_vm._t("default")],
                2
              )
            ]),
            _c("Footer")
          ],
          1
        ),
        _c("RightBar")
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "preloader" } }, [
      _c("div", { attrs: { id: "status" } }, [
        _c("div", { staticClass: "spinner-chase" }, [
          _c("div", { staticClass: "chase-dot" }),
          _c("div", { staticClass: "chase-dot" }),
          _c("div", { staticClass: "chase-dot" }),
          _c("div", { staticClass: "chase-dot" }),
          _c("div", { staticClass: "chase-dot" }),
          _c("div", { staticClass: "chase-dot" })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }