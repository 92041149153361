var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.config,
            expression: "config"
          }
        ],
        staticClass: "right-bar"
      },
      [
        _c("simplebar", { staticClass: "h-100" }, [
          _c("div", { staticClass: "rightbar-title px-3 py-4" }, [
            _c(
              "a",
              {
                staticClass: "right-bar-toggle float-right",
                attrs: { href: "javascript:void(0);" },
                on: { click: _vm.hide }
              },
              [_c("i", { staticClass: "mdi mdi-close noti-icon" })]
            ),
            _c("h5", { staticClass: "m-0" }, [_vm._v("Settings")])
          ]),
          _c(
            "div",
            { staticClass: "p-3" },
            [
              _c("h6", { staticClass: "mb-0" }, [_vm._v("Layout")]),
              _c("hr", { staticClass: "mt-1" }),
              _c(
                "b-form-radio-group",
                {
                  attrs: { stacked: "" },
                  on: {
                    input: function($event) {
                      return _vm.changeLayout($event)
                    }
                  },
                  model: {
                    value: _vm.layout,
                    callback: function($$v) {
                      _vm.layout = $$v
                    },
                    expression: "layout"
                  }
                },
                [
                  _c(
                    "b-form-radio",
                    { staticClass: "mb-1", attrs: { value: "vertical" } },
                    [_vm._v("Vertical")]
                  ),
                  _c(
                    "b-form-radio",
                    { staticClass: "mb-1", attrs: { value: "horizontal" } },
                    [_vm._v("Horizontal")]
                  )
                ],
                1
              ),
              _c("h6", { staticClass: "mt-3" }, [_vm._v("Width")]),
              _c("hr", { staticClass: "mt-1" }),
              _c(
                "b-form-radio-group",
                {
                  attrs: { stacked: "" },
                  on: {
                    input: function($event) {
                      return _vm.changeWidth($event)
                    }
                  },
                  model: {
                    value: _vm.width,
                    callback: function($$v) {
                      _vm.width = $$v
                    },
                    expression: "width"
                  }
                },
                [
                  _c(
                    "b-form-radio",
                    { staticClass: "mb-1", attrs: { value: "fluid" } },
                    [_vm._v("Fluid")]
                  ),
                  _c("b-form-radio", { attrs: { value: "boxed" } }, [
                    _vm._v("Boxed")
                  ])
                ],
                1
              ),
              _vm.layout === "vertical"
                ? _c(
                    "div",
                    [
                      _c("h6", { staticClass: "mt-3" }, [_vm._v("Sidebar")]),
                      _c("hr", { staticClass: "mt-1" }),
                      _c(
                        "b-form-radio-group",
                        {
                          attrs: { stacked: "" },
                          on: {
                            input: function($event) {
                              return _vm.changeType($event)
                            }
                          },
                          model: {
                            value: _vm.sidebarType,
                            callback: function($$v) {
                              _vm.sidebarType = $$v
                            },
                            expression: "sidebarType"
                          }
                        },
                        [
                          _c(
                            "b-form-radio",
                            { staticClass: "mb-1", attrs: { value: "dark" } },
                            [_vm._v("Dark")]
                          ),
                          _c(
                            "b-form-radio",
                            { staticClass: "mb-1", attrs: { value: "light" } },
                            [_vm._v("Light")]
                          ),
                          _c(
                            "b-form-radio",
                            {
                              staticClass: "mb-1",
                              attrs: { value: "compact" }
                            },
                            [_vm._v("Compact")]
                          ),
                          _c(
                            "b-form-radio",
                            { staticClass: "mb-1", attrs: { value: "icon" } },
                            [_vm._v("Icon")]
                          ),
                          _c("b-form-radio", { attrs: { value: "colored" } }, [
                            _vm._v("Colored")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.layout === "horizontal"
                ? _c(
                    "div",
                    [
                      _c("h6", { staticClass: "mt-3" }, [_vm._v("Topbar")]),
                      _c("hr", { staticClass: "mt-1" }),
                      _c(
                        "b-form-radio-group",
                        {
                          attrs: { stacked: "" },
                          on: {
                            input: function($event) {
                              return _vm.changeTopbartype($event)
                            }
                          },
                          model: {
                            value: _vm.topbar,
                            callback: function($$v) {
                              _vm.topbar = $$v
                            },
                            expression: "topbar"
                          }
                        },
                        [
                          _c(
                            "b-form-radio",
                            { staticClass: "mb-1", attrs: { value: "dark" } },
                            [_vm._v("Dark")]
                          ),
                          _c(
                            "b-form-radio",
                            { staticClass: "mb-1", attrs: { value: "light" } },
                            [_vm._v("Light")]
                          ),
                          _c(
                            "b-form-radio",
                            {
                              staticClass: "mb-1",
                              attrs: { value: "colored" }
                            },
                            [_vm._v("Colored")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("h6", { staticClass: "mt-3" }, [_vm._v("Preloader")]),
              _c("hr", { staticClass: "mt-1" }),
              _c(
                "b-form-checkbox",
                {
                  attrs: { name: "check-button", switch: "" },
                  on: { input: _vm.changeloader },
                  model: {
                    value: _vm.loader,
                    callback: function($$v) {
                      _vm.loader = $$v
                    },
                    expression: "loader"
                  }
                },
                [_vm._v("Preloader")]
              )
            ],
            1
          ),
          _c("h6", { staticClass: "text-center mb-0" }, [
            _vm._v("Choose Mode")
          ]),
          _c("div", { staticClass: "p-4" }, [
            _c(
              "div",
              { staticClass: "mb-2" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      tag: "a",
                      target: "_blank",
                      to: "//skote.vuejs-light.themesbrand.com/"
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "img-fluid img-thumbnail",
                      attrs: {
                        src: require("@/assets/images/layouts/layout-1.jpg"),
                        alt: ""
                      }
                    })
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mb-2" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      tag: "a",
                      target: "_blank",
                      to: "//skote.vuejs-dark.themesbrand.com/"
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "img-fluid img-thumbnail",
                      attrs: {
                        src: require("@/assets/images/layouts/layout-2.jpg"),
                        alt: ""
                      }
                    })
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mb-2" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      tag: "a",
                      target: "_blank",
                      to: "//skote.vuejs-rtl.themesbrand.com/"
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "img-fluid img-thumbnail",
                      attrs: {
                        src: require("@/assets/images/layouts/layout-3.jpg"),
                        alt: ""
                      }
                    })
                  ]
                )
              ],
              1
            )
          ])
        ])
      ],
      1
    ),
    _c("div", { staticClass: "rightbar-overlay" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }