var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { attrs: { id: "page-topbar" } }, [
    _c("div", { staticClass: "navbar-header" }, [
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c(
            "div",
            { staticClass: "navbar-brand-box" },
            [
              _c(
                "router-link",
                { staticClass: "logo logo-dark", attrs: { tag: "a", to: "/" } },
                [
                  _c("span", { staticClass: "logo-sm" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/logo.svg"),
                        alt: "",
                        height: "22"
                      }
                    })
                  ]),
                  _c("span", { staticClass: "logo-lg" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/logo-dark.png"),
                        alt: "",
                        height: "17"
                      }
                    })
                  ])
                ]
              ),
              _c(
                "router-link",
                {
                  staticClass: "logo logo-light",
                  attrs: { tag: "a", to: "/" }
                },
                [
                  _c("span", { staticClass: "logo-sm" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/logo-light.svg"),
                        alt: "",
                        height: "22"
                      }
                    })
                  ]),
                  _c("span", { staticClass: "logo-lg" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/logo-light.png"),
                        alt: "",
                        height: "19"
                      }
                    })
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-sm px-3 font-size-16 header-item",
              attrs: { id: "vertical-menu-btn", type: "button" },
              on: { click: _vm.toggleMenu }
            },
            [_c("i", { staticClass: "fa fa-fw fa-bars" })]
          ),
          _c("form", { staticClass: "app-search d-none d-lg-block" }, [
            _c("div", { staticClass: "position-relative" }, [
              _c("input", {
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  placeholder: _vm.$t("navbar.search.text")
                }
              }),
              _c("span", { staticClass: "bx bx-search-alt" })
            ])
          ]),
          _c(
            "b-dropdown",
            {
              staticClass: "dropdown-mega d-none d-lg-block ml-2",
              attrs: {
                variant: "black",
                "toggle-class": "header-item",
                "menu-class": "dropdown-megamenu"
              },
              scopedSlots: _vm._u([
                {
                  key: "button-content",
                  fn: function() {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("navbar.dropdown.megamenu.text")) +
                          " "
                      ),
                      _c("i", { staticClass: "mdi mdi-chevron-down" })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-8" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("h5", { staticClass: "font-size-14 mt-0" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("navbar.dropdown.megamenu.uicontent.title")
                          )
                        )
                      ]),
                      _c("ul", { staticClass: "list-unstyled megamenu-list" }, [
                        _c("li", [
                          _c("a", { attrs: { href: "javascript:void(0);" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "navbar.dropdown.megamenu.uicontent.list.lightbox"
                                )
                              )
                            )
                          ])
                        ]),
                        _c("li", [
                          _c("a", { attrs: { href: "javascript:void(0);" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "navbar.dropdown.megamenu.uicontent.list.rangeslider"
                                )
                              )
                            )
                          ])
                        ]),
                        _c("li", [
                          _c("a", { attrs: { href: "javascript:void(0);" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "navbar.dropdown.megamenu.uicontent.list.sweetalert"
                                )
                              )
                            )
                          ])
                        ]),
                        _c("li", [
                          _c("a", { attrs: { href: "javascript:void(0);" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "navbar.dropdown.megamenu.uicontent.list.rating"
                                )
                              )
                            )
                          ])
                        ]),
                        _c("li", [
                          _c("a", { attrs: { href: "javascript:void(0);" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "navbar.dropdown.megamenu.uicontent.list.forms"
                                )
                              )
                            )
                          ])
                        ]),
                        _c("li", [
                          _c("a", { attrs: { href: "javascript:void(0);" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "navbar.dropdown.megamenu.uicontent.list.tables"
                                )
                              )
                            )
                          ])
                        ]),
                        _c("li", [
                          _c("a", { attrs: { href: "javascript:void(0);" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "navbar.dropdown.megamenu.uicontent.list.charts"
                                )
                              )
                            )
                          ])
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("h5", { staticClass: "font-size-14 mt-0" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("navbar.dropdown.megamenu.application.title")
                          )
                        )
                      ]),
                      _c("ul", { staticClass: "list-unstyled megamenu-list" }, [
                        _c("li", [
                          _c("a", { attrs: { href: "javascript:void(0);" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "navbar.dropdown.megamenu.application.list.ecommerce"
                                )
                              )
                            )
                          ])
                        ]),
                        _c("li", [
                          _c("a", { attrs: { href: "javascript:void(0);" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "navbar.dropdown.megamenu.application.list.calendar"
                                )
                              )
                            )
                          ])
                        ]),
                        _c("li", [
                          _c("a", { attrs: { href: "javascript:void(0);" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "navbar.dropdown.megamenu.application.list.email"
                                )
                              )
                            )
                          ])
                        ]),
                        _c("li", [
                          _c("a", { attrs: { href: "javascript:void(0);" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "navbar.dropdown.megamenu.application.list.projects"
                                )
                              )
                            )
                          ])
                        ]),
                        _c("li", [
                          _c("a", { attrs: { href: "javascript:void(0);" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "navbar.dropdown.megamenu.application.list.tasks"
                                )
                              )
                            )
                          ])
                        ]),
                        _c("li", [
                          _c("a", { attrs: { href: "javascript:void(0);" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "navbar.dropdown.megamenu.application.list.contacts"
                                )
                              )
                            )
                          ])
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("h5", { staticClass: "font-size-14 mt-0" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("navbar.dropdown.megamenu.extrapages.title")
                          )
                        )
                      ]),
                      _c("ul", { staticClass: "list-unstyled megamenu-list" }, [
                        _c("li", [
                          _c("a", { attrs: { href: "javascript:void(0);" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "navbar.dropdown.megamenu.extrapages.list.lightsidebar"
                                )
                              )
                            )
                          ])
                        ]),
                        _c("li", [
                          _c("a", { attrs: { href: "javascript:void(0);" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "navbar.dropdown.megamenu.extrapages.list.compactsidebar"
                                )
                              )
                            )
                          ])
                        ]),
                        _c("li", [
                          _c("a", { attrs: { href: "javascript:void(0);" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "navbar.dropdown.megamenu.extrapages.list.horizontallayout"
                                )
                              )
                            )
                          ])
                        ]),
                        _c("li", [
                          _c("a", { attrs: { href: "javascript:void(0);" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "navbar.dropdown.megamenu.extrapages.list.maintenance"
                                )
                              )
                            )
                          ])
                        ]),
                        _c("li", [
                          _c("a", { attrs: { href: "javascript:void(0);" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "navbar.dropdown.megamenu.extrapages.list.comingsoon"
                                )
                              )
                            )
                          ])
                        ]),
                        _c("li", [
                          _c("a", { attrs: { href: "javascript:void(0);" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "navbar.dropdown.megamenu.extrapages.list.timeline"
                                )
                              )
                            )
                          ])
                        ]),
                        _c("li", [
                          _c("a", { attrs: { href: "javascript:void(0);" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "navbar.dropdown.megamenu.extrapages.list.faqs"
                                )
                              )
                            )
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c("h5", { staticClass: "font-size-14 mt-0" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("navbar.dropdown.megamenu.uicontent.title")
                          )
                        )
                      ]),
                      _c("ul", { staticClass: "list-unstyled megamenu-list" }, [
                        _c("li", [
                          _c("a", { attrs: { href: "javascript:void(0);" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "navbar.dropdown.megamenu.uicontent.list.lightbox"
                                )
                              )
                            )
                          ])
                        ]),
                        _c("li", [
                          _c("a", { attrs: { href: "javascript:void(0);" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "navbar.dropdown.megamenu.uicontent.list.rangeslider"
                                )
                              )
                            )
                          ])
                        ]),
                        _c("li", [
                          _c("a", { attrs: { href: "javascript:void(0);" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "navbar.dropdown.megamenu.uicontent.list.sweetalert"
                                )
                              )
                            )
                          ])
                        ]),
                        _c("li", [
                          _c("a", { attrs: { href: "javascript:void(0);" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "navbar.dropdown.megamenu.uicontent.list.rating"
                                )
                              )
                            )
                          ])
                        ]),
                        _c("li", [
                          _c("a", { attrs: { href: "javascript:void(0);" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "navbar.dropdown.megamenu.uicontent.list.forms"
                                )
                              )
                            )
                          ])
                        ]),
                        _c("li", [
                          _c("a", { attrs: { href: "javascript:void(0);" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "navbar.dropdown.megamenu.uicontent.list.tables"
                                )
                              )
                            )
                          ])
                        ]),
                        _c("li", [
                          _c("a", { attrs: { href: "javascript:void(0);" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "navbar.dropdown.megamenu.uicontent.list.charts"
                                )
                              )
                            )
                          ])
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "col-sm-5" }, [
                      _c("div", [
                        _c("img", {
                          staticClass: "img-fluid mx-auto d-block",
                          attrs: {
                            src: require("@/assets/images/megamenu-img.png"),
                            alt: ""
                          }
                        })
                      ])
                    ])
                  ])
                ])
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c(
            "b-dropdown",
            {
              staticClass: "d-inline-block d-lg-none ml-2",
              attrs: {
                variant: "black",
                "menu-class": "dropdown-menu-lg p-0",
                "toggle-class": "header-item noti-icon",
                right: ""
              },
              scopedSlots: _vm._u([
                {
                  key: "button-content",
                  fn: function() {
                    return [_c("i", { staticClass: "mdi mdi-magnify" })]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("form", { staticClass: "p-3" }, [
                _c("div", { staticClass: "form-group m-0" }, [
                  _c("div", { staticClass: "input-group" }, [
                    _c("input", {
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: "Search ...",
                        "aria-label": "Recipient's username"
                      }
                    }),
                    _c("div", { staticClass: "input-group-append" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "submit" }
                        },
                        [_c("i", { staticClass: "mdi mdi-magnify" })]
                      )
                    ])
                  ])
                ])
              ])
            ]
          ),
          _c(
            "b-dropdown",
            {
              attrs: {
                variant: "white",
                right: "",
                "toggle-class": "header-item"
              },
              scopedSlots: _vm._u([
                {
                  key: "button-content",
                  fn: function() {
                    return [
                      _c("img", {
                        attrs: {
                          src: _vm.flag,
                          alt: "Header Language",
                          height: "16"
                        }
                      }),
                      _vm._v(" " + _vm._s(_vm.text) + " ")
                    ]
                  },
                  proxy: true
                }
              ])
            },
            _vm._l(_vm.languages, function(entry, i) {
              return _c(
                "b-dropdown-item",
                {
                  key: "Lang" + i,
                  staticClass: "notify-item",
                  class: { active: _vm.lan === entry.language },
                  attrs: { value: entry },
                  on: {
                    click: function($event) {
                      return _vm.setLanguage(
                        entry.language,
                        entry.title,
                        entry.flag
                      )
                    }
                  }
                },
                [
                  _c("img", {
                    staticClass: "mr-1",
                    attrs: {
                      src: "" + entry.flag,
                      alt: "user-image",
                      height: "12"
                    }
                  }),
                  _c("span", { staticClass: "align-middle" }, [
                    _vm._v(_vm._s(entry.title))
                  ])
                ]
              )
            }),
            1
          ),
          _c(
            "b-dropdown",
            {
              staticClass: "d-none d-lg-inline-block noti-icon",
              attrs: {
                "menu-class": "dropdown-menu-lg",
                right: "",
                "toggle-class": "header-item",
                variant: "black"
              },
              scopedSlots: _vm._u([
                {
                  key: "button-content",
                  fn: function() {
                    return [_c("i", { staticClass: "bx bx-customize" })]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("div", { staticClass: "px-lg-2" }, [
                _c("div", { staticClass: "row no-gutters" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-icon-item",
                        attrs: { href: "javascript: void(0);" }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/brands/github.png"),
                            alt: "Github"
                          }
                        }),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("navbar.dropdown.site.list.github"))
                          )
                        ])
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-icon-item",
                        attrs: { href: "javascript: void(0);" }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/brands/bitbucket.png"),
                            alt: "bitbucket"
                          }
                        }),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("navbar.dropdown.site.list.github"))
                          )
                        ])
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-icon-item",
                        attrs: { href: "javascript: void(0);" }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/brands/dribbble.png"),
                            alt: "dribbble"
                          }
                        }),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("navbar.dropdown.site.list.dribbble"))
                          )
                        ])
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "row no-gutters" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-icon-item",
                        attrs: { href: "javascript: void(0);" }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/brands/dropbox.png"),
                            alt: "dropbox"
                          }
                        }),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("navbar.dropdown.site.list.dropbox"))
                          )
                        ])
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-icon-item",
                        attrs: { href: "javascript: void(0);" }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/brands/mail_chimp.png"),
                            alt: "mail_chimp"
                          }
                        }),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("navbar.dropdown.site.list.mailchimp")
                            )
                          )
                        ])
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-icon-item",
                        attrs: { href: "javascript: void(0);" }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/brands/slack.png"),
                            alt: "slack"
                          }
                        }),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("navbar.dropdown.site.list.slack"))
                          )
                        ])
                      ]
                    )
                  ])
                ])
              ])
            ]
          ),
          _c("div", { staticClass: "dropdown d-none d-lg-inline-block ml-1" }, [
            _c(
              "button",
              {
                staticClass: "btn header-item noti-icon",
                attrs: { type: "button" },
                on: { click: _vm.initFullScreen }
              },
              [_c("i", { staticClass: "bx bx-fullscreen" })]
            )
          ]),
          _c(
            "b-dropdown",
            {
              attrs: {
                right: "",
                "menu-class": "dropdown-menu-lg p-0",
                "toggle-class": "header-item noti-icon",
                variant: "black"
              },
              scopedSlots: _vm._u([
                {
                  key: "button-content",
                  fn: function() {
                    return [
                      _c("i", { staticClass: "bx bx-bell bx-tada" }),
                      _c(
                        "span",
                        { staticClass: "badge badge-danger badge-pill" },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("navbar.dropdown.notification.badge"))
                          )
                        ]
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("div", { staticClass: "p-3" }, [
                _c("div", { staticClass: "row align-items-center" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("h6", { staticClass: "m-0" }, [
                      _vm._v(
                        _vm._s(_vm.$t("navbar.dropdown.notification.text"))
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "col-auto" }, [
                    _c("a", { staticClass: "small", attrs: { href: "#" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("navbar.dropdown.notification.subtext"))
                      )
                    ])
                  ])
                ])
              ]),
              _c("simplebar", { staticStyle: { "max-height": "230px" } }, [
                _c(
                  "a",
                  {
                    staticClass: "text-reset notification-item",
                    attrs: { href: "javascript: void(0);" }
                  },
                  [
                    _c("div", { staticClass: "media" }, [
                      _c("div", { staticClass: "avatar-xs mr-3" }, [
                        _c(
                          "span",
                          {
                            staticClass:
                              "avatar-title bg-primary rounded-circle font-size-16"
                          },
                          [_c("i", { staticClass: "bx bx-cart" })]
                        )
                      ]),
                      _c("div", { staticClass: "media-body" }, [
                        _c("h6", { staticClass: "mt-0 mb-1" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("navbar.dropdown.notification.order.title")
                            )
                          )
                        ]),
                        _c("div", { staticClass: "font-size-12 text-muted" }, [
                          _c("p", { staticClass: "mb-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "navbar.dropdown.notification.order.text"
                                )
                              )
                            )
                          ]),
                          _c("p", { staticClass: "mb-0" }, [
                            _c("i", { staticClass: "mdi mdi-clock-outline" }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "navbar.dropdown.notification.order.time"
                                  )
                                ) +
                                " "
                            )
                          ])
                        ])
                      ])
                    ])
                  ]
                ),
                _c(
                  "a",
                  {
                    staticClass: "text-reset notification-item",
                    attrs: { href: "javascript: void(0);" }
                  },
                  [
                    _c("div", { staticClass: "media" }, [
                      _c("img", {
                        staticClass: "mr-3 rounded-circle avatar-xs",
                        attrs: {
                          src: require("@/assets/images/users/avatar-3.jpg"),
                          alt: "user-pic"
                        }
                      }),
                      _c("div", { staticClass: "media-body" }, [
                        _c("h6", { staticClass: "mt-0 mb-1" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("navbar.dropdown.notification.james.title")
                            )
                          )
                        ]),
                        _c("div", { staticClass: "font-size-12 text-muted" }, [
                          _c("p", { staticClass: "mb-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "navbar.dropdown.notification.james.text"
                                )
                              )
                            )
                          ]),
                          _c("p", { staticClass: "mb-0" }, [
                            _c("i", { staticClass: "mdi mdi-clock-outline" }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "navbar.dropdown.notification.james.time"
                                  )
                                ) +
                                " "
                            )
                          ])
                        ])
                      ])
                    ])
                  ]
                ),
                _c(
                  "a",
                  {
                    staticClass: "text-reset notification-item",
                    attrs: { href: "javascript: void(0);" }
                  },
                  [
                    _c("div", { staticClass: "media" }, [
                      _c("div", { staticClass: "avatar-xs mr-3" }, [
                        _c(
                          "span",
                          {
                            staticClass:
                              "avatar-title bg-success rounded-circle font-size-16"
                          },
                          [_c("i", { staticClass: "bx bx-badge-check" })]
                        )
                      ]),
                      _c("div", { staticClass: "media-body" }, [
                        _c("h6", { staticClass: "mt-0 mb-1" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("navbar.dropdown.notification.item.title")
                            )
                          )
                        ]),
                        _c("div", { staticClass: "font-size-12 text-muted" }, [
                          _c("p", { staticClass: "mb-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("navbar.dropdown.notification.item.text")
                              )
                            )
                          ]),
                          _c("p", { staticClass: "mb-0" }, [
                            _c("i", { staticClass: "mdi mdi-clock-outline" }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "navbar.dropdown.notification.item.time"
                                  )
                                ) +
                                " "
                            )
                          ])
                        ])
                      ])
                    ])
                  ]
                ),
                _c(
                  "a",
                  {
                    staticClass: "text-reset notification-item",
                    attrs: { href: "javascript: void(0);" }
                  },
                  [
                    _c("div", { staticClass: "media" }, [
                      _c("div", { staticClass: "media-body" }, [
                        _c("h6", { staticClass: "mt-0 mb-1" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "navbar.dropdown.notification.salena.title"
                              )
                            )
                          )
                        ]),
                        _c("div", { staticClass: "font-size-12 text-muted" }, [
                          _c("p", { staticClass: "mb-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "navbar.dropdown.notification.salena.text"
                                )
                              )
                            )
                          ]),
                          _c("p", { staticClass: "mb-0" }, [
                            _c("i", { staticClass: "mdi mdi-clock-outline" }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "navbar.dropdown.notification.salena.time"
                                  )
                                ) +
                                " "
                            )
                          ])
                        ])
                      ])
                    ])
                  ]
                )
              ]),
              _c("div", { staticClass: "p-2 border-top" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-sm btn-light btn-block text-center",
                    attrs: { href: "javascript:void(0)" }
                  },
                  [
                    _c("i", { staticClass: "mdi mdi-arrow-down-circle mr-1" }),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("navbar.dropdown.notification.button")) +
                        " "
                    )
                  ]
                )
              ])
            ],
            1
          ),
          _c(
            "b-dropdown",
            {
              attrs: {
                right: "",
                variant: "black",
                "toggle-class": "header-item"
              },
              scopedSlots: _vm._u([
                {
                  key: "button-content",
                  fn: function() {
                    return [
                      _c("img", {
                        staticClass: "rounded-circle header-profile-user",
                        attrs: {
                          src: require("@/assets/images/users/sontrach.png"),
                          alt: "Header Avatar"
                        }
                      }),
                      _c(
                        "span",
                        { staticClass: "d-none d-xl-inline-block ml-1" },
                        [_vm._v(_vm._s(_vm.$t("navbar.dropdown.henry.text")))]
                      ),
                      _c("i", {
                        staticClass:
                          "mdi mdi-chevron-down d-none d-xl-inline-block"
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "b-dropdown-item",
                [
                  _c(
                    "router-link",
                    { attrs: { tag: "span", to: "/contacts/profile" } },
                    [
                      _c("i", {
                        staticClass: "bx bx-user font-size-16 align-middle mr-1"
                      }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("navbar.dropdown.henry.list.profile")) +
                          " "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "b-dropdown-item",
                { attrs: { href: "javascript: void(0);" } },
                [
                  _c("i", {
                    staticClass: "bx bx-wallet font-size-16 align-middle mr-1"
                  }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("navbar.dropdown.henry.list.mywallet")) +
                      " "
                  )
                ]
              ),
              _c(
                "b-dropdown-item",
                {
                  staticClass: "d-block",
                  attrs: { href: "javascript: void(0);" }
                },
                [
                  _c(
                    "span",
                    { staticClass: "badge badge-success float-right" },
                    [_vm._v("11")]
                  ),
                  _c("i", {
                    staticClass: "bx bx-wrench font-size-16 align-middle mr-1"
                  }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("navbar.dropdown.henry.list.settings")) +
                      " "
                  )
                ]
              ),
              _c(
                "b-dropdown-item",
                { attrs: { href: "javascript: void(0);" } },
                [
                  _c("i", {
                    staticClass:
                      "bx bx-lock-open font-size-16 align-middle mr-1"
                  }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("navbar.dropdown.henry.list.lockscreen")) +
                      " "
                  )
                ]
              ),
              _c("b-dropdown-divider"),
              _c(
                "a",
                {
                  staticClass: "dropdown-item text-danger",
                  attrs: { href: "/logout" }
                },
                [
                  _c("i", {
                    staticClass:
                      "bx bx-power-off font-size-16 align-middle mr-1 text-danger"
                  }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("navbar.dropdown.henry.list.logout")) +
                      " "
                  )
                ]
              )
            ],
            1
          ),
          _c("div", { staticClass: "dropdown d-inline-block" }, [
            _c(
              "button",
              {
                staticClass:
                  "btn header-item noti-icon right-bar-toggle toggle-right",
                attrs: { type: "button" },
                on: { click: _vm.toggleRightSidebar }
              },
              [_c("i", { staticClass: "bx bx-cog bx-spin toggle-right" })]
            )
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }