var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12" }, [
      _c(
        "div",
        {
          staticClass:
            "page-title-box d-flex align-items-center justify-content-between"
        },
        [
          _c(
            "h4",
            { staticClass: "mb-0 font-size-22" },
            [_vm._v(" " + _vm._s(_vm.title) + " "), _vm._t("datePicker")],
            2
          ),
          _c(
            "div",
            {},
            [
              _c("b-breadcrumb", {
                staticClass: "m-0",
                attrs: { items: _vm.items }
              })
            ],
            1
          ),
          _vm._t("buttons")
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }