var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { attrs: { id: "page-topbar" } }, [
    _c("div", { staticClass: "navbar-header" }, [
      _c("div", { staticClass: "d-flex" }, [
        _c(
          "div",
          { staticClass: "navbar-brand-box" },
          [
            _c(
              "router-link",
              { staticClass: "logo logo-dark", attrs: { to: "/" } },
              [
                _c("span", { staticClass: "logo-sm" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/camplus_logo_white.png"),
                      alt: "",
                      height: "22"
                    }
                  })
                ]),
                _c("span", { staticClass: "logo-lg" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/camplus_logo_white.png"),
                      alt: "",
                      height: "17"
                    }
                  })
                ])
              ]
            ),
            _c(
              "router-link",
              { staticClass: "logo logo-light", attrs: { to: "/" } },
              [
                _c("span", { staticClass: "logo-sm" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/camplus_logo_white.png"),
                      alt: "",
                      height: "90"
                    }
                  })
                ]),
                _c("span", { staticClass: "logo-lg" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/camplus_logo_white.png"),
                      alt: "",
                      height: "85"
                    }
                  })
                ])
              ]
            )
          ],
          1
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-sm mr-2 font-size-16 d-lg-none header-item",
            attrs: { id: "toggle", type: "button" },
            on: { click: _vm.toggleMenu }
          },
          [_c("i", { staticClass: "fa fa-fw fa-bars" })]
        )
      ]),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("div", { staticClass: "dropdown d-none d-lg-inline-block ml-1" }, [
            _c(
              "button",
              {
                staticClass: "btn header-item noti-icon",
                attrs: { type: "button" },
                on: { click: _vm.initFullScreen }
              },
              [_c("i", { staticClass: "bx bx-fullscreen" })]
            )
          ]),
          _c(
            "b-dropdown",
            {
              attrs: {
                right: "",
                "menu-class": "dropdown-menu-lg p-0",
                "toggle-class": "header-item noti-icon",
                variant: "black"
              },
              scopedSlots: _vm._u([
                {
                  key: "button-content",
                  fn: function() {
                    return [
                      _c("i", { staticClass: "bx bx-bell bx-tada" }),
                      _c(
                        "span",
                        {
                          staticClass: "badge badge-danger badge-pill",
                          on: { click: _vm.fetchNotifications }
                        },
                        [_vm._v(_vm._s(_vm.notifications.countNotifs))]
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("div", { staticClass: "p-3" }, [
                _c("div", { staticClass: "row align-items-center" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("h6", { staticClass: "m-0" }, [_vm._v("Notifications")])
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-auto" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "small",
                          attrs: { to: { name: "settings.user.notifications" } }
                        },
                        [_vm._v("Voir tous")]
                      )
                    ],
                    1
                  )
                ])
              ]),
              _vm._l(_vm.notifications.notificationsList, function(
                notif,
                index
              ) {
                return _c(
                  "simplebar",
                  { key: index, staticStyle: { "max-height": "230px" } },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "text-reset notification-item",
                        attrs: { href: "javascript: void(0);" },
                        on: {
                          click: function($event) {
                            return _vm.markAsRead(notif.id)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "media" }, [
                          _c("div", { staticClass: "avatar-xs mr-3" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "avatar-title rounded-circle font-size-16",
                                class: "bg-" + notif.class
                              },
                              [
                                _c("i", {
                                  staticClass: "bx",
                                  class: notif.icon
                                })
                              ]
                            )
                          ]),
                          _c("div", { staticClass: "media-body" }, [
                            _c("h6", { staticClass: "mt-0 mb-1" }, [
                              _vm._v(_vm._s(notif.title))
                            ]),
                            _c(
                              "div",
                              { staticClass: "font-size-12 text-muted" },
                              [
                                notif.extra
                                  ? _c("p", { staticClass: "mb-1" }, [
                                      _c("b", [_vm._v(_vm._s(notif.extra.msg))])
                                    ])
                                  : _c("p", { staticClass: "mb-1" }, [
                                      _vm._v(_vm._s(notif.emitedBy))
                                    ]),
                                _c("p", { staticClass: "mb-0" }, [
                                  _c("i", {
                                    staticClass: "mdi mdi-clock-outline"
                                  }),
                                  _vm._v(" " + _vm._s(notif.generatedAt) + " ")
                                ])
                              ]
                            )
                          ])
                        ])
                      ]
                    )
                  ]
                )
              }),
              _c("div", { staticClass: "p-2 border-top" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-sm btn-light btn-block text-center",
                    attrs: { href: "javascript:void(0)" }
                  },
                  [
                    _c("i", { staticClass: "mdi mdi-arrow-down-circle mr-1" }),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("navbar.dropdown.notification.button")) +
                        " "
                    )
                  ]
                )
              ])
            ],
            2
          ),
          _c(
            "b-dropdown",
            {
              attrs: {
                right: "",
                variant: "black",
                "toggle-class": "header-item"
              },
              scopedSlots: _vm._u([
                {
                  key: "button-content",
                  fn: function() {
                    return [
                      _c(
                        "span",
                        { staticClass: "d-none d-xl-inline-block ml-1" },
                        [
                          _vm._v(
                            _vm._s(_vm.user.first_name) +
                              " " +
                              _vm._s(_vm.user.last_name)
                          )
                        ]
                      ),
                      _c("i", {
                        staticClass:
                          "mdi mdi-chevron-down d-none d-xl-inline-block"
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "b-dropdown-item",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        tag: "span",
                        to: { name: "settings.user.profile" }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "bx bx-user font-size-16 align-middle mr-1"
                      }),
                      _vm._v(" Mon profile ")
                    ]
                  )
                ],
                1
              ),
              _c("b-dropdown-divider"),
              _c(
                "a",
                {
                  staticClass: "dropdown-item text-danger",
                  attrs: { href: "javascript:void()" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.signout($event)
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass:
                      "bx bx-power-off font-size-16 align-middle mr-1 text-danger"
                  }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("navbar.dropdown.henry.list.logout")) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }