<script>
import i18n from "../i18n";

import simplebar from "simplebar-vue";
import { layoutComputed } from "@/state/helpers";
import { mapGetters, mapActions } from 'vuex';
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...layoutComputed,
    ...mapGetters({
      authenticated: 'apiAuth/authenticated',
      user: 'apiAuth/user',
    })
  },
  components: {
    simplebar,
  },
  data() {
    return {
      notifUrl : require('@/assets/mp3/notification_1.mp3'),
      notifications: {
        countNotifs: 0,
        notificationsList: [],
      },
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
        {
          flag: require("@/assets/images/flags/french.jpg"),
          language: "fr",
          title: "French",
        },
        {
          flag: require("@/assets/images/flags/spain.jpg"),
          language: "es",
          title: "spanish",
        },
        {
          flag: require("@/assets/images/flags/chaina.png"),
          language: "zh",
          title: "Chinese",
        },
        {
          flag: require("@/assets/images/flags/arabic.png"),
          language: "ar",
          title: "Arabic",
        },
      ],
      current_language: i18n.locale,
      text: null,
      flag: null,
      value: null,
    };
  },
  mounted() {
    this.value = this.languages.find((x) => x.language === i18n.locale);
    this.text = this.value.title;
    this.flag = this.value.flag;
    this.fetchNotifications();
    
    // listen for incoming booking events
    // window.Echo.channel(`booking.checkin.${this.user.id}`).listen('.booking.checkin.event', (e) => {
    //   this.fetchNotifications();
    //   this.$toast.info(e.msg, e.title);
    //   this.playNotif();
    // });


    // // listen for incoming Rest Service orders events
    // window.Echo.channel(`so.rest.generated.${this.user.id}`).listen('.so.rest.generated.event', (e) => {
    //   this.fetchNotifications();
    //   this.$toast.info(e.msg, e.title);
    //   this.playNotif();
    // });

    // window.Echo.channel(`so.rest.approved.${this.user.id}`).listen('.so.rest.approved.event', (e) => {
    //   this.fetchNotifications();
    //   this.$toast.info(e.msg, e.title);
    //   this.playNotif();
    // });
    
  },
  methods: {

    fetchNotifications(){
      this.$http
        .post("/auth/notifications")
        .then((res) => {
          this.notifications.countNotifs = res.data.count;
          this.notifications.notificationsList = res.data.notifs;
        })
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },

    markAsRead(notifId){
      this.$http
        .post("/auth/notification/markAsRead", {
          notifId: notifId,
        })
        .then((res) => {
          this.fetchNotifications();
        })
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },

    playNotif(){
      var successNotification = new Audio(this.notifUrl);
      successNotification.play();
    },

    ...mapActions({
      signoutAction: "apiAuth/logout",
    }),
    signout(){
      this.signoutAction(this.$browserDetect.meta).then(() => {
        this.$router.replace({
          name: 'login',
        });
      });
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    toggleMenu() {
      let element = document.getElementById("topnav-menu-content");
      element.classList.toggle("show");
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    setLanguage(locale, country, flag) {
      this.current_language = locale;
      this.text = country;
      this.flag = flag;
      i18n.locale = locale;
    },
  },
  watch: {
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "dark":
              document.body.setAttribute("data-topbar", "dark");
              break;
            case "light":
              document.body.setAttribute("data-topbar", "light");
              document.body.removeAttribute("data-layout-size", "boxed");
              break;
            case "colored":
              document.body.setAttribute("data-topbar", "colored");
              document.body.removeAttribute("data-layout-size", "boxed");
              break;
            default:
              document.body.setAttribute("data-topbar", "dark");
              break;
          }
        }
      },
    },
    width: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "boxed":
              document.body.setAttribute("data-layout-size", "boxed");
              break;
            case "fluid":
              document.body.setAttribute("data-layout-mode", "fluid");
              document.body.removeAttribute("data-layout-size");
              break;
            default:
              document.body.setAttribute("data-layout-mode", "fluid");
              break;
          }
        }
      },
    },
  },
};
</script>

<style>
.nav-bar {
  max-width: 100% !important;
}
</style>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/camplus_logo_white.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/camplus_logo_white.png" alt height="17" />
            </span>
          </router-link>

          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/camplus_logo_white.png" alt height="90" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/camplus_logo_white.png" alt height="85" />
            </span>
          </router-link>
        </div>
        <button
          id="toggle"
          type="button"
          class="btn btn-sm mr-2 font-size-16 d-lg-none header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>
      </div>

      <div class="d-flex">

        <div class="dropdown d-none d-lg-inline-block ml-1">
          <button type="button" class="btn header-item noti-icon" @click="initFullScreen">
            <i class="bx bx-fullscreen"></i>
          </button>
        </div>

        <b-dropdown
          right
          menu-class="dropdown-menu-lg p-0"
          toggle-class="header-item noti-icon"
          variant="black"
          
        >
          <template v-slot:button-content>
            <i class="bx bx-bell bx-tada"></i>
            <span  @click="fetchNotifications" class="badge badge-danger badge-pill">{{ notifications.countNotifs }}</span>
          </template>

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">Notifications</h6>
              </div>
              <div class="col-auto">
                <router-link class="small" :to="{name: 'settings.user.notifications'}">Voir tous</router-link>
              </div>
            </div>
          </div>
          <simplebar  style="max-height: 230px;" v-for="(notif, index) in notifications.notificationsList" v-bind:key="index">
            <a href="javascript: void(0);" @click="markAsRead(notif.id)" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span class="avatar-title rounded-circle font-size-16" :class="'bg-'+notif.class">
                    <i class="bx" :class="notif.icon"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ notif.title }}</h6>
                  <div class="font-size-12 text-muted">
                    <p v-if="notif.extra" class="mb-1"><b>{{ notif.extra.msg }}</b></p>
                    <p v-else class="mb-1">{{ notif.emitedBy }}</p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ notif.generatedAt }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </simplebar>
          <div class="p-2 border-top">
            <a class="btn btn-sm btn-light btn-block text-center" href="javascript:void(0)">
              <i class="mdi mdi-arrow-down-circle mr-1"></i>
              {{ $t('navbar.dropdown.notification.button')}}
            </a>
          </div>
        </b-dropdown>

        <b-dropdown right variant="black" toggle-class="header-item">
          <template v-slot:button-content>
            <span class="d-none d-xl-inline-block ml-1">{{  user.first_name }} {{ user.last_name }}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <!-- item-->
          <b-dropdown-item>
            <router-link tag="span" :to="{ name: 'settings.user.profile' }">
              <i class="bx bx-user font-size-16 align-middle mr-1"></i>
              Mon profile
            </router-link>
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <a href="javascript:void()" @click.prevent="signout" class="dropdown-item text-danger">
            <i class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
            {{ $t('navbar.dropdown.henry.list.logout') }}
          </a>
        </b-dropdown>

        <!-- <div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn header-item noti-icon right-bar-toggle toggle-right"
            @click="toggleRightSidebar"
          >
            <i class="bx bx-cog bx-spin toggle-right"></i>
          </button>
        </div> -->
      </div>
    </div>
  </header>
</template>