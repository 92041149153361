var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "footer" }, [
    _c("div", { staticClass: "container-fluid" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-6" }, [
          _vm._v(
            _vm._s(new Date().getFullYear()) + " © Azimut Business Solutions."
          )
        ]),
        _vm._m(0)
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-6" }, [
      _c("div", { staticClass: "text-sm-right d-none d-sm-block" }, [
        _vm._v("Développé par: "),
        _c("a", { attrs: { href: "https://azimutbs.dz", target: "_blank" } }, [
          _vm._v("Azimut Business Solutions")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }